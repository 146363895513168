var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ReportTable',{attrs:{"name":"Drone","item-key":"id","headers":_vm.filteredHeaders,"filter-fields":_vm.filters,"provider":_vm.ingestAPI.drone.getDrones,"copy-to-clipboard":false,"hide-show":false,"show-bookmarks":false},scopedSlots:_vm._u([{key:"actions-left",fn:function(){return [_c('ERow',{attrs:{"justify":"start","align":"center"}},[_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.refreshTable()}}},[_c('v-icon',[_vm._v("fa-rotate-right")])],1),_vm._v(" "),_c('div',{staticClass:"body-1 text--secondary my-3"},[_vm._v("\n        Queued Drones ("+_vm._s(_vm.droneQueueSize)+") | Total Queued ("+_vm._s(_vm.totalQueueSize)+")\n      ")])],1)]},proxy:true},{key:"actions-right",fn:function(){return [_c('ERow',{staticClass:"mr-1"},[_c('v-spacer'),_vm._v(" "),(_vm.allowUpload)?_c('v-btn',{staticClass:"text-center ml-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){_vm.droneUploadDialog = true}}},[_vm._v("\n        New "+_vm._s(_vm.ingestUploadTypes.droneUploads)+"\n      ")]):_vm._e(),_vm._v(" "),_c('CreateUploadDialog',{attrs:{"upload-dialog":_vm.droneUploadDialog,"ingest-upload-type":_vm.ingestUploadTypes.droneUploads,"provider":_vm.generateDroneUploadLink,"upload-object":_vm.droneUploadObject},on:{"close-dialog":function($event){return _vm.onDroneUplaodDialogClosed()}}})],1)]},proxy:true},{key:"item.projectId",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.projectId)+"\n      "),(
          item.flightDate &&
          item.processingStatus === _vm.IngestProcessingStatus.Completed
        )?_c('OpenInNewWindowButton',{attrs:{"target-link":`${_vm.$config.public.dashURL}/projects/${
          item.projectId
        }/drone?${_vm.formatFlightDate(item.flightDate)}`}}):_vm._e(),_vm._v(" "),(item.processingStatus === _vm.IngestProcessingStatus.Uploading)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"pr-1 ma-0",style:({ minWidth: 'unset' }),attrs:{"color":"primary","small":"","text":""},on:{"click":function($event){return _vm.copyProjectId(item.projectId)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":""}},[_vm._v("far fa-copy")])],1)]}}],null,true)},[_vm._v("\n        Copy\n      ")]):_vm._e()],1)]}},{key:"item.processingStatus",fn:function({ item }){return [_c('ERow',{attrs:{"justify":"center","align":"center"}},[(
          (!item.processingStatus && !item.consumed) ||
          item.processingStatus == _vm.IngestProcessingStatus.Created
        )?_c('v-chip',{staticClass:"chip-size",attrs:{"small":"","rounded":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.openDroneUploadDialog(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("\n          fa fa-external-link-alt\n        ")]),_vm._v("\n        "+_vm._s(item.processingStatus)+"\n      ")],1):(
          item.processingStatus &&
          item.processingStatus == _vm.IngestProcessingStatus.InProgress
        )?_c('v-chip',{staticClass:"chip-size",attrs:{"small":"","rounded":"","outlined":"","color":"primary"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":15,"width":"1","color":"primary"}},[_vm._v(_vm._s(item.processingStatus))])],1):(
          item.processingStatus &&
          (item.processingStatus == _vm.IngestProcessingStatus.Failed ||
            item.processingStatus == _vm.IngestProcessingStatus.Pending ||
            item.processingStatus == _vm.IngestProcessingStatus.Aborted)
        )?_c('v-chip',{staticClass:"chip-size",attrs:{"small":"","rounded":"","outlined":"","color":item.hasTusLinks ? '#FBC02D' : 'primary'},on:{"click":function($event){item.hasTusLinks && _vm.openReprocessDialog(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":item.hasTusLinks ? '#FBC02D' : 'primary'}},[_vm._v("\n          "+_vm._s(item.hasTusLinks
              ? "fa-solid fa-rotate-right"
              : "fa-solid fa-clock-o")+"\n        ")]),_vm._v("\n        "+_vm._s(item.processingStatus)+"\n      ")],1):(
          item.processingStatus &&
          item.processingStatus === _vm.IngestProcessingStatus.Uploading
        )?_c('v-chip',{staticClass:"chip-size",attrs:{"small":"","rounded":"","outlined":"","color":"primary"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("fa-regular fa-circle-up")]),_vm._v("\n        "+_vm._s(item.processingStatus))],1):_c('v-chip',{staticClass:"chip-size",attrs:{"small":"","rounded":"","outlined":"","color":"success","target":"_blank","href":`https://ion.cesium.com/tilesetlocationeditor/${item.modelCesiumAssetId}`}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success"}},[_vm._v(" fa-solid fa-check ")]),_vm._v("\n        "+_vm._s(item.processingStatus))],1)],1)]}},{key:"item.modelZip",fn:function({ item }){return [(item.modelZip)?_c('ArchiveDownloadLabel',{attrs:{"dataId":item.id,"dataType":"model","download-archive":_vm.ingestAPI.drone.getArchive}}):_vm._e()]}},{key:"item.orthomosaicZip",fn:function({ item }){return [(item.orthomosaicZip)?_c('ArchiveDownloadLabel',{attrs:{"dataId":item.id,"dataType":"orthomosaic","download-archive":_vm.ingestAPI.drone.getArchive}}):_vm._e()]}},{key:"item.reportPdf",fn:function({ item }){return [(item.reportPdf)?_c('ArchiveDownloadLabel',{attrs:{"dataId":item.id,"dataType":"report","download-archive":_vm.ingestAPI.drone.getArchive}}):_vm._e()]}},{key:"item.flightGeolocation",fn:function({ item }){return [(item.flightGeolocation)?_c('span',[_vm._v("Open In Google Maps\n      "),_c('OpenInNewWindowButton',{attrs:{"target-link":`http://maps.google.com/?q=${item.flightGeolocation}`}})],1):_vm._e()]}},{key:"item.token",fn:function({ item }){return [_vm._v("\n    "+_vm._s(item.token ? item.token.substring(0, 9) : "")+"\n  ")]}},{key:"item.delete",fn:function({ item }){return [_c('ERow',{attrs:{"justify":"center","align":"center"}},[_c('v-btn',{attrs:{"icon":"","color":"primary","target":"_blank"},on:{"click":function($event){return _vm.openDeleteDialog(item)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" fas fa-trash ")])],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }