<template>
  <ReportTable
    name="BIM"
    item-key="id"
    :headers="filteredHeaders"
    :filter-fields="filters"
    :provider="ingestAPI.bim.getBIMs"
    :copy-to-clipboard="false"
    :hide-show="false"
    :show-bookmarks="false"
  >
    <template #actions-left>
      <div class="body-1 text--secondary my-3">BIMs</div>
    </template>
    <template #actions-right>
      <ERow class="mr-1">
        <v-spacer />
        <v-btn
          v-if="allowUpload"
          small
          color="primary"
          class="text-center ml-2"
          @click="bimUploadDialog = true"
        >
          New {{ ingestUploadTypes.bimUploads }}
        </v-btn>
        <CreateUploadDialog
          :upload-dialog="bimUploadDialog"
          :ingest-upload-type="ingestUploadTypes.bimUploads"
          :provider="generateBimUploadLink"
          :upload-object="bimUploadObject"
          @close-dialog="onBIMUplaodDialogClosed()"
        />
      </ERow>
    </template>
    <template #item.link="{ item }">
      <ERow justify="center" align="center">
        <v-btn
          v-if="item.files && item.files.length <= 0"
          :disabled="!allowUpload"
          icon
          color="primary"
          target="_blank"
          @click="openBIMUploadDialog(item)"
        >
          <v-icon color="primary"> fa fa-external-link-alt </v-icon>
        </v-btn>
        <v-icon v-else color="success"> fa-solid fa-check </v-icon>
      </ERow>
    </template>
    <template #item.files="{ item }">
      <v-card
        v-if="item.files && item.files.length > 0"
        elevation="0"
        class="transparent align-center justify-start"
      >
        <ERow no-gutters justify="start" align="center">
          <v-icon color="primary" small @click="toggleItemVisibility(item)">
            {{
              visibileItems.includes(item.id)
                ? "fa-solid fa-chevron-up"
                : "fa-solid fa-chevron-down"
            }}
          </v-icon>
          <ArchiveDownloadLabel
            :dataType="getArchiveName(item.archive)"
            :dataId="item.id"
            :download-archive="ingestAPI.bim.getArchive"
          />
        </ERow>
        <v-card-text
          v-if="visibileItems.includes(item.id)"
          class="d-flex flex-wrap align-center justify-start pa-0"
        >
          <span
            v-for="(file, index) in formatFilesNames(item.files)"
            :key="index"
          >
            <v-chip
              small
              class="truncate-text ma-1"
              rounded
              outlined
              :color="colorByFormat(file)"
            >
              <div class="truncate-text" v-text="file" />
            </v-chip>
          </span>
        </v-card-text>
      </v-card>
    </template>
    <template #item.delete="{ item }">
      <ERow justify="center" align="center">
        <v-btn
          icon
          color="primary"
          target="_blank"
          @click="openDeleteDialog(item)"
        >
          <v-icon color="red"> fas fa-trash </v-icon>
        </v-btn>
      </ERow>
    </template>
  </ReportTable>
</template>

<script>
import CreateUploadDialog from "@/components/ingest/uploadDialogs/CreateUploadDialog"
import ArchiveDownloadLabel from "@/components/ingest/ArchiveDownloadLabel"
import ReportTable from "@/components/ReportTable"
import { BIM_HEADER } from "@/components/ingest/ingestHeaders"
import { FILTERS_BIM } from "@/components/ingest/ingestFilters"
import { INGEST_UPLOADS_TYPES } from "@evercam/shared/constants/ingest"
import { IngestApi } from "@evercam/shared/api/ingestApi"

export default {
  components: {
    CreateUploadDialog,
    ReportTable,
    ArchiveDownloadLabel,
  },
  props: {
    allowUpload: {
      type: Boolean,
      default: true,
    },
    allowDelete: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      headers: BIM_HEADER,
      filters: FILTERS_BIM,
      ingestUploadTypes: INGEST_UPLOADS_TYPES,
      bimUploadObject: undefined,
      bimUploadDialog: false,
      visibileItems: [],
    }
  },
  computed: {
    ingestAPI() {
      return IngestApi
    },
    filteredHeaders() {
      return this.headers.filter((header) => {
        if (header.value === "delete") {
          return this.allowDelete
        }

        return true
      })
    },
  },
  methods: {
    async openDeleteDialog(deletedItem) {
      if (
        await this.$confirmDialog.open({
          title: "Delete BIM upload",
          message:
            "Are you sure you want to delete this  upload ?. If you are not from the 3D team please contact them before doing this action",
        })
      ) {
        this.deleteBimUpload(deletedItem.id)
      }
    },
    async deleteBimUpload(id) {
      try {
        await IngestApi.bim.deleteUpload(id)
        this.$notifications.success("Successfully deleted entry")
      } catch (e) {
        this.$notifications.error({ text: "Couldn't delete entry", error: e })
      } finally {
        this.$root.$emit("refresh-report-table")
      }
    },
    async generateBimUploadLink(payload) {
      try {
        const response = await IngestApi.bim.createUpload(
          payload.project.exid,
          {
            uploadedBy: payload.uploadedBy,
          }
        )
        this.$root.$emit("refresh-report-table")

        this.bimUploadObject = {
          ...response,
          name: payload.project.name,
        }
      } catch (e) {
        this.$notifications.error({ text: "Couldn't get token : ", error: e })

        return ""
      }
    },
    getArchiveName(archive) {
      if (!archive || archive === "") {
        return ""
      }
      const parts = archive.split("/")
      let fileNameWithExtension = parts[parts.length - 1]

      return fileNameWithExtension.split(".")[0]
    },
    openBIMUploadDialog(upload) {
      this.bimUploadObject = upload
      this.bimUploadDialog = true
    },
    onBIMUplaodDialogClosed() {
      this.bimUploadDialog = false
      this.bimUploadObject = undefined
    },
    formatFilesNames(files) {
      return files.map((file) => {
        return file.split("/").at(-1) ?? ""
      })
    },
    colorByFormat(file) {
      const format = file.split(".").at(-1)
      switch (format) {
        case "rvt":
          return "orange"
        case "nwd":
          return "primary"
        case "nwc":
          return "blue"
        case "sp":
          return "success"
        case "ifc":
          return "purple"
        default:
          return "white"
      }
    },
    toggleItemVisibility(item) {
      if (!this.visibileItems.includes(item.id)) {
        this.visibileItems.push(item.id)
      } else {
        this.visibileItems = this.visibileItems.filter((id) => id !== item.id)
      }
    },
  },
}
</script>
